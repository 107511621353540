<template>
	<div class="containar">
		<div class="class-box">
			<div class="box">
				<div class="box1">
					<div class="title-head">
						<div class="title">
							<span class="title1">全部课程</span
							><span class="small-title">畅学海量课程</span>
						</div>
						<div class="serch" ref="serch">
							<el-input
								placeholder="请输入课程名称"
								v-model="input"
								size="small"
								class="input-with-select"
								@keyup.enter.native="handleSelect()"
							>
								<!-- <el-button
									@click="handleSelect()"
									slot="append"
									icon="el-icon-search"
									>搜索</el-button
								> -->
							</el-input>
							<span @click="handleSelect()"
								><i class="el-icon-search"></i
							></span>
						</div>
					</div>
					<div class="nav">
					<!--	<div class="row">
							<strong class="title">面向人群</strong>
							<div class="rowBox">
								<span
									@click="handleSelect('forCrowd', i)"
									:class="
										forCrowd.select == i
											? 'row-item active'
											: 'row-item'
									"
									v-for="(item, i) in forCrowd.data"
									:key="i"
									>{{ item.dictValue }}</span
								>
							</div>
						</div>-->
							<div class="row">
							<strong class="title">课程类别</strong>
							<div class="rowBox">
								<span
									@click="handleSelect('courseType', i)"
									:class=" courseType.select == i ? 'row-item active': 'row-item'"
									v-for="(item, i) in courseType.data"
									:key="i"
									>{{ item.dictValue }}</span
								>
							</div>
						</div>
						<div class="row">
							<strong class="title">开设场地</strong>
							<div class="rowBox">
								<span
									@click="handleSelect('openSpace', i)"
									:class="
										openSpace.select == i
											? 'row-item active'
											: 'row-item'
									"
									v-for="(item, i) in openSpace.data"
									:key="i"
									>{{ item.deptName }}</span
								>
							</div>
						</div>
						<div class="row">
							<strong class="title">预约状态</strong>
							<div class="rowBox">
								<span
									@click="handleSelect('status', i)"
									:class="
										status.select == i
											? 'row-item active'
											: 'row-item'
									"
									v-for="(item, i) in status.data"
									:key="i"
									>{{ item.dictValue }}</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="heat">
				<span
					@click="handleSelect('heat', i)"
					:class="heat.select == i ? 'row-item active' : 'row-item'"
					v-for="(item, i) in heat.data"
					:key="i"
					>{{ item.dictValue }}</span
				>
			</div>
			<div v-if="courseList.length > 0" class="list">
				<el-card
					v-for="(item, i) in courseList"
					:key="i"
					:body-style="{ padding: '0px' }"
				>
					<el-container>
						<el-aside width="330px">
							<el-image
								style="width: 302px; height: 214px"
								:src="item.courseInfo.coursePicture"
								fit="cover"
								alt="加载失败"
							></el-image>
						</el-aside>
						<el-main>
							<h4 class="title">
								{{ item.courseInfo.courseName }}
							</h4>
							<p class="icon" v-if="item.courseInfo.courseSite">
								<i class="el-icon-office-building"> </i>
								<span class="infoName">课程场景：</span
								><span class="info">{{
									item.courseSite.siteName
								}}</span>
							</p>
							<p class="icon" v-if="item.courseInfo.courseRoom">
								<i class="el-icon-school"> </i>
								<span class="infoName">上课教室：</span
								><span class="info">{{
									item.courseInfo.courseRoom
								}}</span>
							</p>
							<p class="icon" v-if="item.courseSite.siteAddress">
								<i class="el-icon-location"> </i>
								<span class="infoName">上课地址：</span
								><span class="info">{{
									item.courseSite.siteAddress
								}}</span>
							</p>
							<p class="icon">
								<i class="el-icon-s-custom"> </i>
								<span class="infoName">限制人数：</span
								><span class="info">{{
									(item.courseRule.selectMaxNumber = ''
										? '暂无'
										: item.courseRule.selectMaxNumber)
								}}</span>
							</p>
							<p
								class="icon"
								v-if="item.courseInfo.courseStatus == 1 &&item.courseInfo.status == 1">
								<i class="el-icon-collection"> </i>
								<span class="infoName">预约人数：</span
								><span class="info"
									>{{
										item.courseRule.selectMaxNumber -
											item.courseInfo.courseStock
									}}/{{
										item.courseRule.selectMaxNumber
									}}</span
								>
							</p>
						<!--	<p class="icon">
								<i class="el-icon-reading"></i>
								<span class="infoName">面向人群：</span
								><span class="info">
									{{
										item.courseInfo.courseLimitGrade
									}}年级</span
								>
							</p>-->
							<p class="icon">
								<i class="el-icon-alarm-clock"></i>
								<span class="infoName">上课时间：</span
								><span class="info" v-if="item.courseInfoTime[0].timeType == 1">
									{{ item.courseInfoTime[0].time }}</span
								><span class="info" v-if="item.courseInfoTime[0].timeType == 2">
									<span class="info-round" v-for="(roundItem,index) in item.courseInfoTime" :key="index">{{ roundItem.time }}</span></span
								>
							</p>
              <p>
                <i class="el-icon-refrigerator"></i>
                <span class="infoName">课程价格：</span>
                <span>￥0</span>
              </p>
							<div class="handle">
								<div class="left">
									<span> </span>
								</div>
								<div class="right">
									<button
										@click="
											$router.push(
												'/course/courseInfo?courseId=' +
													item.courseInfo.id
											)
										"
										class="btn"
									>
										查看详情
									</button>
									<button
										@click="
											handleAppointment(item.courseInfo)
										"
										v-if="
											item.courseInfo.courseStatus == 1
										"
										class="btn"
									>
										立即预约
									</button>
									<button
										@click="
											handleAppointment(item.courseInfo)
										"
										v-else-if="
											item.courseInfo.courseStatus == 8
										"
										class="btn"
									>
										立即候补
									</button>
									<button
										v-else-if="
											item.courseInfo.courseStatus == 3
										"
										class="btn"
										@click="
											$message.error(
												'该课程当前暂不可预约'
											)
										"
									>
										不可预约
									</button>
									<button
										v-else-if="
											item.courseInfo.courseStatus == 7
										"
										class="btn"
										@click="
											$message.error(
												'该课程还没开始，当前暂不可预约'
											)
										"
									>
										即将开始
									</button>
								</div>
							</div>
						</el-main>
					</el-container>
				</el-card>
				<el-pagination
					v-if="total > pageSize"
					@current-change="changePage"
					background
					:page-size="pageSize"
					:current-page="currentPage"
					layout="prev, pager, next"
					:total="total"
				>
				</el-pagination>
			</div>

			<EmptyBox v-else class="emptyBox">暂无符合条件的课程</EmptyBox>
		</div>
	</div>
</template>

<script>
import bg from '@/assets/img/courseBg.jpg'
import moment from 'moment'
import { set } from '@/utils/local.js'
import EmptyBox from '@/components/empty.vue'
import {
	getGeneration_api,
	getOpenSite_api,
	getCourseStatus_api,
	getFilCourseList_api,
	getCourseTimeType_api,
} from '@/api/course.js'
// import { getFilCourseList_api } from '@/api/json/user.js'
export default {
	components: {
		EmptyBox,
	},
	data() {
		return {
			courseType:{},//课程类别
			forCrowd: {}, // 面向人群数据
			openSpace: {}, // 开设场地
			heat: {
				title: '热度',
				select: 0,
				data: [
					{ descs: '', dictValue: '综合' },
					{ descs: 'create_time', dictValue: '最新' },
					{ descs: 'course_heat', dictValue: '最热' },
				],
			},
			status: {}, // 预约状态
			input: undefined, // 搜索值
			courseList: [], // 课程数据
			currentPage: 1, // 当前页码
			pageSize: 6, // 每页条数
			total: 0, // 总条数
			courseHotList: [], //热门课程
		}
	},
	methods: {
		upData(list) {
			// 数据处理
			if (!list) return
			list.forEach((item) => {
				if (item.courseLimitGrade) item.courseLimitGrade += '年级'
				if (item.courseLabel && typeof item.courseLabel === 'string')
					item.courseLabel = item.courseLabel.split(',')
				// 开始时间
				let sTime = moment(new Date(item.courseStartTime)).format(
					'HH:mm'
				)
				// 结束时间
				let eTime = moment(new Date(item.courseEndTime)).format('HH:mm')
				let arr = ['一', '二', '三', '四', '五', '六', '日']
				item.timer = sTime + '-' + eTime
				item.time =
					moment(new Date(item.courseStartWeek)).format(
						'YYYY-MM-DD'
					) +
					' 至 ' +
					moment(new Date(item.courseEndWeek)).format('YYYY-MM-DD') +
					' 每周' +
					(!isNaN(Number(item.courseDay))
						? arr[item.courseDay - 1]
						: item.courseDay) +
					' ' +
					item.timer
			})
		},
		// 获取年级筛选条件
		async getCourseType() {
			let res = await getCourseTimeType_api({ code: 'course_time_type' })
			res.data.unshift({ dictValue: '全部' }) // 数组开头添加一个全部
			res.select = 0 // 增加当前选中项的索引，默认选中全部,
			if (res.code == 200) this.courseType = res
		},
		// 获取年级筛选条件
		async getGeneration() {
			let res = await getGeneration_api({ code: 'target_generation' })
			res.data.unshift({ dictValue: '全部' }) // 数组开头添加一个全部
			res.select = 0 // 增加当前选中项的索引，默认选中全部,
			if (res.code == 200) this.forCrowd = res
		},
		// 获取开设场地筛选条件
		async getOpenSite() {
			let res = await getOpenSite_api({ tenantId: this.$tenantId })
			res.data.unshift({ deptName: '全部' }) // 数组开头添加一个全部
			res.select = 0 // 增加当前选中项的索引，默认选中全部,
			if (res.code == 200) this.openSpace = res
		},
		// 获取预约状态筛选条件
		async getCourseStatus() {
			let res = await getCourseStatus_api({ code: 'course_status' })
			// 过滤出可预约和不可预约
			res.data = res.data.filter(
				(item) =>
					item.dictValue.includes('预约') ||
					item.dictValue.includes('候补') ||
					item.dictValue.includes('即将开始')
			)
			res.data.unshift({ dictValue: '全部' }) // 数组开头添加一个全部
			res.select = 0 // 增加当前选中项的索引，默认选中全部,
			if (res.code == 200) this.status = res
		},
		// 获取筛选后课程列表
		async getFilCourseList() {
			// 数据准备
			function getAtt(obj) {
				if (obj.select && obj.select != 0) {
					let index = obj.select
					return (
						obj.data[index].dictKey ||
						obj.data[index].id ||
						obj.data[index].descs
					)
				}
			}
			// 发送请求
			let { code, data } = await getFilCourseList_api({
				// courseInfo,
				// query
				courseLimitGrade: getAtt(this.forCrowd),
				courseStatus:
					getAtt(this.status) == 3 ? '3' : getAtt(this.status), //预约状态
				createDept: getAtt(this.openSpace), //开设场地
				courseName: this.input,
				current: this.currentPage,
				size: this.pageSize,
				descs: getAtt(this.heat),
				timeType:getAtt(this.courseType),
			})
			if (code == 200) {
				// this.upData(data.records)
				data.records.forEach((item, i) => {
					if (item.courseInfoTime[0].timeType == 1) {
						item.courseInfoTime[0].time =
							moment(
								new Date(item.courseInfoTime[0].startDay)
							).format('YYYY-MM-DD') +
							' 至 ' +
							moment(
								new Date(item.courseInfoTime[0].endDay)
							).format('YYYY-MM-DD') +
							' 每周' +
							item.courseInfoTime[0].dayOfWeek +
							' ' +
							item.courseInfoTime[0].startTime +
							'-' +
							item.courseInfoTime[0].endTime
					} else if (item.courseInfoTime[0].timeType == 2) {
						item.courseInfoTime.forEach((itemRound, i) => {
							itemRound.time =
								moment(
									new Date(itemRound.date)
								).format('YYYY-MM-DD') +'  '+
								itemRound.startTime +
								'-' +
								itemRound.endTime
						})
					}
				})
				this.courseList = data.records
				console.log(this.courseList)
				this.total = data.total
			}
		},
		// 改变筛选条件
		handleSelect(row, i = 0) {
			// 避免重复点击
			if (row && this[row].select == i) return null
			// 改变条件后，先修改当前页码为1
			this.currentPage = 1
			// 当有传入值时调整激活选项
			if (row) this[row].select = i
			// 发送请求
			this.getFilCourseList()
		},
		// 切换页码
		changePage(currentPage) {
			this.currentPage = currentPage
			document.documentElement.scrollTop = document.body.scrollTop =
				this.$refs.serch.offsetTop - 100
			// 发送请求
			this.getFilCourseList()
		},
		// 立即预约
		handleAppointment(item) {
			set('course', item)
			if (!this.$store.state.isLogin) {
				// 如果没登录
				this.$router.push(
					'/login?redirect=/course/courseAdd?courseId=' + item.id
				)
			} else {
				this.$router.push('/course/courseAdd?courseId=' + item.id)
			}
		},
	},
	created() {
		this.getGeneration()
		this.getOpenSite()
		this.getCourseStatus()
		this.getFilCourseList()
		this.getCourseType()
	},
}
</script>

<style lang="less" scoped>
@btn-color: rgb(246, 50, 50);
@title-color: #0079fe;
.containar {
	// #f66866
	margin: 0;
	width: 100%;
	.class-box {
		margin: 0 auto;
		padding: 0 0 40px;
		background-color: #fff;
		.box {
			.box1 {
				width: 100%;
				margin: 0 auto;
				padding: 0;
				background-image: url('../../assets/img/courseBg.jpg');
				background-size: 100% 100%;
				background-position: center 0;
				// background-color: rgb(240, 244, 243);
				.title-head {
					width: 60vw;
					color: #fff;
					margin: 0 auto;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					.title {
						padding: 50px 0;
						.title1 {
							font-size: 25px;
							letter-spacing: 3px;
							font-weight: bolder;
							// color: black;
						}
						.small-title {
							padding-left: 10px;
							letter-spacing: 2px;
							font-size: 6px;
						}
					}
					.serch {
						width: 15vw;
						padding: 50px 0;
						display: flex;
						flex-direction: row;
						/deep/.el-input__inner {
							border: 0;
							border-radius: 0px;
							border-bottom: 1px solid #fff;
							background: transparent;
							color: #fff;
						}
						span {
							line-height: 31px;
							border-bottom: 1px solid #fff;
						}
						// .el-input {
						// 	width: 100%;
						// }
						// /deep/.el-input-group__append {
						// 	height: 100%;
						// 	background-color: rgb(195, 197, 197);
						// 	color: #fff;
						// 	border: 1px solid rgb(195, 197, 197);
						// }
					}
				}
				.nav {
					width: 60vw;
					margin: 0 auto;
					padding: 0 30px 20px;
					.row {
						color: #fff;
						display: flex;
						align-items: center;
						font-size: 14px;
						padding: 20px 0;
						.title {
							display: inline-block;
							width: 160px;
							text-align: center;
							font-weight: 700;
						}
						.rowBox {
							flex: 1;
							.row-item {
								display: inline-block;
								padding: 4px 16px;
								margin: 8px;
								cursor: pointer;
								user-select: none;

								&:hover {
									border-radius: 4px;
									background-color: @btn-color;
									color: #fff;
								}
							}
							.active {
								border-radius: 4px;
								background-color: @btn-color;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.heat {
			width: 60vw;
			margin: 20px auto 0;
			.row-item {
				display: inline-block;
				padding: 4px 16px;
				margin: 0 8px;
				cursor: pointer;
				user-select: none;

				&:hover {
					color: @btn-color;
				}
			}
			.active {
				border-bottom: 4px solid @btn-color;
				color: @btn-color;
				font-weight: bold;
			}
		}
		.list {
			width: 60vw;
			margin: 0 auto;
			margin-bottom: 100px;
			.el-card {
				margin: 30px 0;
				.el-aside {
					padding: 14px;
				}
				.el-main {
					padding: 14px 14px 14px 0;
					line-height: 1.5em;
					font-size: 14px;
					.title {
						font-size: 18px;
						font-weight: 700;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						letter-spacing: 2px;
						padding-bottom: 21px;
					}
					.intro {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						letter-spacing: 2px;
					}
					.icon {
						color: #555;
						// height: 25px;
						line-height: 25px;
						display: grid;
						grid-template-columns: 20px 80px auto;
						i {
							height: 25px;
							line-height: 25px;
						}
                        .info-round{
                            display: block;
                        }
					}
					.handle {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.left {
							white-space: nowrap;
							overflow: hidden;
							span {
								margin-right: 10px;
								padding: 2px 20px;
								border-radius: 4px;
								background-color: #fff;
								letter-spacing: 2px;
							}
						}
						.right {
							width: 230px;
							.btn {
								margin-left: 15px;
								padding: 5px 20px;
								border-radius: 50px;
								background-color: @btn-color;
								&:first-child {
									background-color: @title-color;
								}
							}
						}
					}
				}
			}
			// 分页
			.el-pagination {
				display: flex;
				justify-content: center;
			}
		}
		.emptyBox {
			margin: 100px 0;
		}
	}
}
</style>
